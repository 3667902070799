import React from "react"
import TemplatePageInstagram from "@components/pageChannels/pageInstagram/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div>
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Automatiza tus respuestas en Instagram con chatbots  [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/chatbot-instagram/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Responde rápidamente todos los DM utilizando chatbots con Inteligencia Artificial. Mejora la interacción con tus usuarios brindado experiencias únicas."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Automatiza tus respuestas en Instagram con chatbots "
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/chatbot-instagram/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Responde rápidamente todos los DM utilizando chatbots con Inteligencia Artificial. Mejora la interacción con tus usuarios brindado experiencias únicas."
      />
    </Helmet>
    <TemplatePageInstagram location={location} />
  </div>
)

export default IndexPage
