import React from "react"

export const TitleUnderlineInstagramOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="231" height="10" viewBox="0 0 231 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M42.9465 10C38.1707 9.07169 3.20097 10.1081 0.65916 9.82831C-1.41599 9.45317 1.90028 2.84689 3.82649 1.87407C4.50166 1.52436 5.28604 1.47351 7.08318 1.63247C9.93279 1.93131 46.7791 1.33997 46.7791 1.33997C47.2656 1.54344 48.7251 0.856744 48.9932 1.10472C49.8372 1.68332 109.56 0.894891 114.653 0.8631C117.741 0.844025 115.299 1.23824 118.933 1.07928C126.677 0.761364 143.556 1.06657 149.424 0.704142C154.121 0.405302 155.134 0.392594 159.641 0.63421C164.248 0.850392 188.743 0.538832 191.543 0.074676C192.228 -0.0524901 192.754 -0.00797814 192.754 0.138263C192.754 0.170054 210.021 0.278142 210.1 0.392591C210.229 0.538832 210.755 0.608769 211.242 0.507036C212.146 0.354437 230.524 -0.198737 230.812 0.227269C231.715 1.44806 229.184 6.64915 227.049 7.91446C224.865 9.26878 212.87 7.74915 203.229 8.56302L158.341 8.64568L148.719 9.15434C147.617 8.71561 143.378 9.82196 140.816 9.03353C139.734 8.72197 132.089 8.67745 130.401 9.00809C128.931 9.26242 89.821 8.62023 85.8097 9.50403C78.6609 8.76647 49.6684 9.82197 42.9465 10Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineInstagramTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="151" height="10" viewBox="0 0 151 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.0733 10C24.9514 9.07169 2.09241 10.1081 0.43088 9.82831C-0.925602 9.45317 1.24217 2.84689 2.5013 1.87407C2.94264 1.52436 3.45538 1.47351 4.63013 1.63247C6.49286 1.93131 30.5785 1.33997 30.5785 1.33997C30.8966 1.54344 31.8506 0.856744 32.0259 1.10472C32.5776 1.68332 71.617 0.894891 74.9465 0.8631C76.965 0.844025 75.3684 1.23824 77.7439 1.07928C82.8063 0.761364 93.8399 1.06657 97.6757 0.704142C100.746 0.405302 101.408 0.392594 104.354 0.63421C107.366 0.850392 123.377 0.538832 125.208 0.074676C125.656 -0.0524901 126 -0.00797814 126 0.138263C126 0.170054 137.286 0.278142 137.338 0.392591C137.423 0.538832 137.767 0.608769 138.085 0.507036C138.675 0.354437 150.689 -0.198737 150.877 0.227269C151.468 1.44806 149.813 6.64915 148.417 7.91446C146.989 9.26878 139.149 7.74915 132.847 8.56302L103.504 8.64568L97.2149 9.15434C96.4945 8.71561 93.7231 9.82196 92.0486 9.03353C91.3411 8.72197 86.3436 8.67746 85.2402 9.00809C84.2796 9.26242 58.7142 8.62023 56.0921 9.50403C51.419 8.76647 32.4672 9.82197 28.0733 10Z" fill="#FFBA00" />
    </svg>
  </span>
)