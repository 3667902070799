import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="294" height="10" viewBox="0 0 294 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M54.6592 10C48.5809 9.07169 4.07396 10.1081 0.838932 9.82831C-1.80217 9.45317 2.41854 2.84689 4.87008 1.87407C5.72939 1.52436 6.72769 1.47351 9.01496 1.63247C12.6417 1.93131 59.537 1.33997 59.537 1.33997C60.1562 1.54344 62.0138 0.856744 62.355 1.10472C63.4291 1.68332 139.44 0.894891 145.922 0.8631C149.852 0.844025 146.744 1.23824 151.369 1.07928C161.226 0.761364 182.708 1.06657 190.177 0.704142C196.154 0.405302 197.443 0.392594 203.18 0.63421C209.043 0.850392 240.218 0.538832 243.782 0.074676C244.654 -0.0524901 245.324 -0.00797814 245.324 0.138263C245.324 0.170054 267.299 0.278142 267.4 0.392591C267.564 0.538832 268.234 0.608769 268.853 0.507036C270.003 0.354437 293.394 -0.198737 293.761 0.227269C294.911 1.44806 291.688 6.64915 288.971 7.91446C286.191 9.26878 270.926 7.74915 258.656 8.56302L201.524 8.64568L189.279 9.15434C187.877 8.71561 182.481 9.82196 179.22 9.03353C177.843 8.72197 168.113 8.67745 165.964 9.00809C164.094 9.26242 114.318 8.62023 109.212 9.50403C100.114 8.76647 63.2143 9.82197 54.6592 10Z" fill="#FFA700"/>
</svg>
  </span>
)


export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="263" height="10" viewBox="0 0 263 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M48.8958 10C43.4584 9.07169 3.64439 10.1081 0.750473 9.82831C-1.61214 9.45317 2.16352 2.84689 4.35657 1.87407C5.12527 1.52436 6.01831 1.47351 8.0644 1.63247C11.3088 1.93131 53.2593 1.33997 53.2593 1.33997C53.8132 1.54344 55.475 0.856744 55.7802 1.10472C56.741 1.68332 124.737 0.894891 130.536 0.8631C134.052 0.844025 131.271 1.23824 135.408 1.07928C144.226 0.761364 163.443 1.06657 170.124 0.704142C175.471 0.405302 176.624 0.392594 181.756 0.63421C187.001 0.850392 214.889 0.538832 218.077 0.074676C218.857 -0.0524901 219.456 -0.00797814 219.456 0.138263C219.456 0.170054 239.115 0.278142 239.205 0.392591C239.352 0.538832 239.951 0.608769 240.505 0.507036C241.534 0.354437 262.458 -0.198737 262.786 0.227269C263.815 1.44806 260.932 6.64915 258.502 7.91446C256.015 9.26878 242.359 7.74915 231.382 8.56302L180.275 8.64568L169.321 9.15433C168.067 8.71561 163.24 9.82196 160.323 9.03353C159.091 8.72197 150.386 8.67745 148.465 9.00809C146.792 9.26242 102.264 8.62023 97.6968 9.50403C89.5576 8.76647 56.5489 9.82197 48.8958 10Z" fill="#FFA700" />
    </svg>
  </span>
)
