import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="358" height="12" viewBox="0 0 358 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M66.3977 10.3354C58.1209 9.5144 4.31423 11.8497 1.23132 11.7341C-2.03383 11.4665 1.91473 5.02631 4.73946 4.00464C6.47075 3.38433 6.34926 3.83436 21.2778 3.38433C21.2778 3.38433 43.7846 2.72754 71.4549 1.96128C71.4549 1.96128 115.907 0.970008 177.109 0.738914C181.923 0.72067 178.111 1.1038 183.776 0.970008C195.85 0.696345 222.123 1.21327 231.265 0.994334C238.6 0.817973 240.18 0.805811 247.181 1.14029C251.995 1.37138 292.392 1.94304 296.872 1.59639C297.965 1.51126 298.755 1.56599 298.74 1.70586C299.059 1.71802 325.529 2.55118 325.651 2.67889C325.833 2.82484 326.638 2.91606 327.413 2.84309C328.84 2.70321 357.467 3.13499 357.847 3.56069C358.94 4.77698 353.944 9.5813 350.436 10.6942C346.806 11.8436 329.205 9.82455 313.653 10.1712C241.121 8.37718 241.683 8.83328 229.564 9.07046C227.894 8.62651 221.303 9.5813 217.384 8.82112C215.744 8.49881 203.959 8.37718 201.317 8.65084C200.755 8.71165 196.548 8.69949 190.474 8.65692C187.436 8.63868 183.928 8.60827 180.177 8.58394C175.165 8.54746 137.244 8.38326 132.49 9.0887C119.703 8.38326 78.5623 9.90969 66.3977 10.3354Z" fill="#FFBA00"/>
      </svg>
  </span>
)

export default titleUnderline
