import React, { useEffect, useRef } from "react"
import { Link } from "gatsby"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"

const Operations = ({ data, location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  return (
    <section className="container-conversions">
      <p className="container-conversions-title">
      Potencia tu canal de Instagram con <TitleUnderlineOne underline="funcionalidades únicas" /> de nuestra plataforma omnicanal    
      </p>
      <div className="container-conversions-cards-desktop">
        {data.map((elemento, i) => {
          return (
            <div
              key={i}
              className="container-conversions-cards-desktop-item"
            >       
                <p className="container-conversions-cards-desktop-item-title">
                  {elemento.title}
                </p>
                <p className="container-conversions-cards-desktop-item-description">
                  {elemento.text}
                </p>
                <img className="container-conversions-cards-desktop-item-imagen" loading="lazy" src={elemento.img} alt="..." />
            </div>
          )
        })}
      </div>

      <div className="container-conversions-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <div key={i}>
                  <div className="container-conversions-cards-mobile-item">                 
                    <p className="container-conversions-cards-mobile-item-title">
                      {elemento.title}
                    </p>
                    <p className="container-conversions-cards-mobile-item-description">
                      {elemento.text}
                    </p>
                    <img className="container-conversions-cards-mobile-item-imagen" loading="lazy" src={elemento.img} alt="..." />
                  </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Operations
