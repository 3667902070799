/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageChannels/pageInstagram/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageChannels/components/intro/intro"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"
import AccordionRight from "@components/sharedComponents/accordionRight/accordionRight"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import Experience from "@components/pageChannels/pageInstagram/experience/experience"
import { TitleUnderlineRightOne, TitleUnderlineRightTwo } from "@components/pageChannels/pageInstagram/titleUnderline/titleUnderlineAccordionRight"
import TitleUnderlineTestimony from "@components/pageChannels/pageInstagram/titleUnderline/titleUnderlineTestimony"
import monedas from "@components/sharedComponents/testimonies/images/iconos/monedas.svg"
import callcenter from "@components/sharedComponents/testimonies/images/iconos/callcenter.svg"
import ecommerce from "@components/sharedComponents/testimonies/images/iconos/ecommerce.svg"
import cobros from "@components/sharedComponents/testimonies/images/iconos/cobros.svg"
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import Conversions from "@components/pageChannels/pageInstagram/conversions/conversions"

const TemplatePageInstagram = ({ location }) => {

  //Video youtube
  const [video, setVideo] = useState(false)
  const openVideo = value => {
    setVideo(value)
  }

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true


  const titleAccordionRight = <p>
    En Beex <TitleUnderlineRightOne underline="mejoramos la" /> <TitleUnderlineRightTwo underline="conexión" />  con tus clientes en diferentes etapas de compra por Instagram
  </p>
  const titleTestimony = <p>
    Negocios en todo el mundo mejoran sus operaciones con  <TitleUnderlineTestimony underline="automatizaciones" /> en Instagram

  </p>

  const iconsSilder = i => {
    switch (i) {
      case 0:
        return ecommerce
      case 1:
        return monedas
      case 2:
        return callcenter
      case 3:
        return cobros
      default:
        return ""
    }
  }
  const titleSilder = i => {
    switch (i) {
      case 0:
        return "Televentas"
      case 1:
        return "Cobros"
      case 2:
        return "Atención al cliente"
      case 3:
        return "Cobros"
      default:
        return "Conoce otros casos de éxito"
    }
  }

  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        <div className="banner-invisible-ebook" />
        <div>
          <Header
            path="/productos/"
            location={location}
            bannerCTA={true}
            windowsWidth={windowsWidth}
          />
        </div>
        {video ?
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideo(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/lDaQp4xkA1c?autoplay=1"
                  title="Beex Teams"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </> : ""}


        <div className="container">
          <Start data={data.start} location={location} imageViewResponsive={false} background={"linear-gradient(72.71deg, #FFEBB7 7.31%, #FFD5CC 34.86%, #D7E4FB 61.97%, #DED6F7 97.65%)"} />
          <Customers data1={data.customersDesktop} data2={data.customersTablet} title="Únete a las empresas que ya brindan experiencias conversacionales únicas a sus clientes" />
          <AccordionRight data={data.accordionRight} title={titleAccordionRight} description={""} />
          <BannerCTA
            title="Mejora tu ticket de compra con automatizaciones en Instagram"
            text="Conversa con nuestros expertos para que potencies la comunicación vía Instagram de tu empresa."
            link="/hablemos/"
            textButton="Agendar una reunión"
          />
          <Testimonies data={data.testimonies} location={location} title={titleTestimony} iconsSilder={iconsSilder} titleSilder={titleSilder} />
          <Experience data={data.modules} location={location} />
          <BannerCTA
            title="Crea experiencias únicas conversacionales por Instagram"
            text="Nuestro equipo comercial está dispuesto a ayudarte con tu caso de uso en específico."
            link="/hablemos/"
            textButton="Agendar una reunión"
          />
          <Conversions data={data.conversions} location={location} />
          <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
          <ArticleBlog data={data.articles} home={true} />
          <BannerContact data={data.contacts} location={location} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageInstagram
