import React from "react"

export const TitleUnderlineRightOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="226" height="10" viewBox="0 0 226 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M41.888 9.2234C37.2359 8.3478 3.17115 9.37785 0.686353 9.13714C-1.34755 8.79269 1.73848 2.6106 3.59549 1.6901C4.25201 1.36521 5.0229 1.29522 6.79232 1.457C8.20405 1.57931 17.9113 1.50338 27.2796 1.38193C36.6479 1.26048 45.6775 1.12917 45.6775 1.12917C46.149 1.3225 47.5938 0.677873 47.8585 0.908093C48.274 1.17902 63.0986 1.13784 78.4497 1.04597C93.8009 0.954098 109.679 0.841239 112.162 0.83232C115.191 0.820137 112.766 1.18533 116.34 1.05696C123.939 0.785617 140.459 1.18601 146.214 0.884103C150.841 0.64244 151.819 0.648473 156.223 0.889584C160.702 1.13619 184.707 1.03413 187.489 0.643026C188.166 0.53199 188.674 0.576496 188.675 0.713196C188.995 0.729113 205.552 1.02825 205.609 1.12895C205.723 1.26498 206.232 1.3392 206.72 1.24712C207.622 1.11096 225.663 0.806853 225.891 1.20966C226.594 2.36447 223.481 7.18561 221.287 8.35182C218.98 9.58409 207.513 8.03008 198.035 8.69307C146.315 8.27153 156.606 8.26937 144.926 8.77911C143.889 8.35737 139.718 9.35116 137.23 8.61714C136.212 8.31416 128.781 8.23976 127.089 8.52924C125.229 8.84944 87.9916 7.91922 83.6323 8.81309C76.7047 8.09376 48.434 9.04752 41.888 9.2234Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineRightTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="157" height="10" viewBox="0 0 157 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.1432 9.09631C25.9103 8.21222 2.25153 9.17998 0.525196 8.93474C-0.888139 8.58657 1.24405 2.41019 2.53221 1.4931C2.98762 1.16941 3.52294 1.10083 4.75224 1.26584C5.73303 1.39073 12.4753 1.33254 18.9822 1.22822C25.489 1.1239 31.7605 1.0091 31.7605 1.0091C32.0884 1.20328 33.0907 0.561303 33.275 0.792005C33.5641 1.06368 43.8609 1.04961 54.5233 0.985798C65.1857 0.921986 76.2139 0.838151 77.9387 0.833772C80.0425 0.827125 78.359 1.18788 80.841 1.06604C86.1186 0.808595 97.5942 1.23918 101.591 0.947797C104.804 0.714594 105.484 0.722415 108.543 0.971573C111.654 1.22636 128.328 1.16818 130.259 0.782168C130.729 0.67237 131.082 0.717805 131.083 0.854505C131.305 0.871006 142.806 1.2004 142.846 1.30121C142.925 1.43744 143.279 1.51259 143.618 1.42141C144.244 1.2869 156.774 1.01577 156.933 1.41899C157.424 2.57508 155.27 7.39047 153.748 8.55266C152.148 9.78069 144.181 8.20575 137.599 8.8514C101.674 8.33532 108.822 8.35198 100.71 8.84036C99.9892 8.41673 97.0942 9.40288 95.3649 8.66433C94.6575 8.35949 89.4954 8.27151 88.3211 8.55789C87.0293 8.87469 61.1635 7.87641 58.1372 8.7623C53.3241 8.03032 33.6896 8.9324 29.1432 9.09631Z" fill="#FFBA00" />
    </svg>

  </span>
)